<template>
	<b-container fluid  v-if="$checkPermission('list-role')" id="roles">
		<cg-modal :data="data" @update="updateRole"></cg-modal>

		<!-- Role deletion confirmation modal -->
		<b-modal id="role-delete-confirm"  content-class="shadow" size='md' buttonSize='md' okVariant='danger' 
			bodyClass="text-center"  @show="userHasConfirmed = ''" footerClass='p-2' :centered='true' 
			:okDisabled="userHasConfirmed != 'OK'" :title="$t('Roles.ConfirmDeleteTitle')" @ok="deleteRole">
			
			<p class="my-2 text-left">{{ $t('Roles.ConfirmDeleteMessage', { param: roleToDelete.name }) }}</p>
			<p class="my-2 text-left" v-html="$t('General.ConfirmNotReversible')"></p>
			<input class="text-center mt-4" v-model="userHasConfirmed" />
		</b-modal>

		<cg-loader :display="inProgress"></cg-loader>

		<cg-localized-alert :alertVariant="alertVariant" ref="cgLocAlert" :alertMessage="alertMessage"
			:alertParams="alertParams" :dismissSecs="dismissSecs"></cg-localized-alert>

		<div class="px-4 mb-4">
			<b-card-title class="mb-4">{{ $t('Navbar.Roles') }}</b-card-title>

			<!-- Role table filter -->
			<b-row align-h="end" class="mb-4">
				<b-col lg="8" md="12">

					<!-- New Role button -->
					<b-row align-h="end">
						<b-col cols="auto">
							<b-button v-if="$checkPermission('create-role')" @click="addNewRole()" size="sm" variant="primary" class="mb-3">
								<font-awesome-icon class="vertical-align-middle mr-2" :icon="['fas', 'plus']"/>{{ $t('Roles.NewRole') }}
							</b-button>
						</b-col>
					</b-row>

					<b-form-group :label="$t('General.Search')" label-cols-sm="3" label-cols-md="4" label-cols-xl="5" label-align-sm="right" label-size="sm" 
						label-for="filterInput" class="mb-0"><b-input-group size="sm">
							<b-form-input v-model="filter" type="search" debounce="500" id="filterInput" 
								:placeholder="$t('General.TypeToSearch')"></b-form-input>
							<template v-slot:append>
								<b-input-group-text class="icon-append">
									<font-awesome-icon class="vertical-align-middle" :icon="['fas', 'search']"/>
								</b-input-group-text>
							</template>
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>

			<!-- Role table -->
			<b-table
				id="roleListTable"
				ref="roleListTable"
				:items="getCurrentRolePage"
				:per-page="perPage"
				:current-page="currentPage"
				:sort-by.sync="sortBy"
				:sort-desc.sync="sortDesc"
				:filter="filter"
                :fields="fields"
				hover striped show-empty bordered no-sort-reset
				:emptyFilteredText="$t('General.TableFilterEmpty')"
				:empty-text="$t('General.TableEmpty')">
				
				<!-- Additional template info for action column -->
				<template v-slot:cell(actions)="row">
					<div class="table-row-center">
						<div v-if="$checkPermission('delete-role')" class="action delete">
							<font-awesome-icon class="mx-2" :icon="['fas', 'trash-alt']"
								@click="confirmDeleteRole(row.index, row.item)"/>
						</div>
						<div v-if="$checkPermission('modify-role')" class="action edit">
							<font-awesome-icon class="mx-2" :icon="['fas', 'pencil-alt']"
								@click="editRole(row.index, row.item)"/>
						</div>
					</div>
				</template>
			</b-table>

			<!-- Pagination widget -->
			<b-row align-h='end'>
				<b-col class="mb-2 d-block d-sm-block d-md-none">
					<label for="itemsPerPageSmall" class="text-right">{{ $t('Analytics.Table.ItemsPerPage') }}</label>
					<b-form-select id="itemsPerPageSmall" class="float-right" v-model="perPage" :options="pageSizeOptions"></b-form-select>
				</b-col>
				<b-col class="text-right pt-1 pr-0 d-none d-sm-none d-md-block">
					<label for="itemsPerPage" class="text-right">{{ $t('Analytics.Table.ItemsPerPage') }}</label>
				</b-col>
				<b-col cols="12" md="auto" class="mb-2 pr-0 d-none d-sm-none d-md-block">
					<!-- Items per Page widget -->
					<b-form-select id="itemsPerPage" class="float-right" v-model="perPage" :options="pageSizeOptions"></b-form-select>
				</b-col>

				<b-col cols="12" md="auto">
					<b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" 
					aria-controls="roleListTable"></b-pagination>
				</b-col>
			</b-row>
			
		</div>
	</b-container>
</template>

<script>
import roleService from "@/services/role.service.js";
import syspropService from "@/services/sysprop.service.js";
import { TablePaginationSize } from "../../../server/common/constants";
import Vue from 'vue';

export default {
	name: "cg-roles",
    data: function () {
		return {
            permissions: [],
            roles: [],

            alertVariant: '',
            alertParams: {},
            alertMessage: '',
            dismissSecs: 5,

            fields: [],
            filter: '',
			perPage: 15,
			currentPage: 1,
            totalRows: 1,
            sortBy: "name",
            sortDesc: false,
			
			inProgress: false,
            
			userHasConfirmed: '',
			
			roleToDelete: {},

			data: {
				name: "role-modal",
				title: 'Roles.NewRole',
				label: 'Roles.NewRole',
				item: {},
				submitHandler: "updateRole",
				fields:[
					{   id: "name",
						label: 'Roles.Name',
						type: "text",
						display: true,
						required: true,
						placeholder: 'Roles.Name'
					},
					{   id: "permissions",
						label: 'Roles.AvailablePermissions',
						type: "tabbed-checkbox",
						sections: [],
						display: true,
						required: false,
						model: [],
						dataset: {},
						key: "permission_id",
						placeholder: 'Roles.AvailablePermissions'
					}
				]	
			}
		}
	},
	computed: {
		pageSizeOptions() {
			return TablePaginationSize || [];
		}
	},
	methods: {
		addNewRole() {
			console.debug("Roles - addNewRole");
			this.data.item = { name: '', permissions: [] };
			Vue.set(this.data.fields.find(i => i.id === 'permissions'), 'model', []);

			this.data.title = 'Roles.NewRole';
			this.data.label = 'Roles.NewRole';
			
			this.$bvModal.show("role-modal");
		},
		async editRole(index, record) {
			console.debug("Roles - editRole", index, record);

			this.data.title = 'Roles.EditRole';
            this.data.label = 'General.Save';
            
            this.$bvModal.show("role-modal");

            roleService.getRolePermissions(record.role_id).then((res) => {
				record.permissions = res.data.map((perm) => perm.permission_id);

				Vue.set(this.data.fields.find(i => i.id === 'permissions'), 'model', record.permissions);
				this.data.item = record;
				
				this.$bvModal.show("role-modal");
			});
		},
		confirmDeleteRole(index, item) {
			console.debug("Roles - confirmDeleteRole - opening confirmation modal for", index, item);
			
			this.roleToDelete = item;
			this.$bvModal.show("role-delete-confirm");
        },
        updateRole(newRole) {
			console.debug("Roles - updateRole", newRole);
			this.inProgress = true;

			let rolePromise;

			if (newRole.role_id) {
				rolePromise = roleService.updateRole(newRole);
			} else {
				rolePromise = roleService.createRole(newRole);
			}

			rolePromise.then((res) => {
				console.debug("Roles - updateRole success", res);
				this.alertVariant = 'success';
				this.alertMessage = newRole.role_id ? 'Roles.RoleModified' : 'Roles.RoleCreated';
				
				// Refresh table to reload data
				this.$nextTick(() => {
					this.$refs.roleListTable.refresh();
				});
			}).catch((error) => {
				console.error("Roles - updateRole error", error);
				let msg = error.response.data && error.response.data.errors ? error.response.data.errors[0].msg : '';
				if (error.response.status == 409) {
					this.alertMessage = 'Roles.RoleAlreadyExists';
					this.alertParams = { param: newRole.name };
					this.dismissSecs = 30;
				} else if (error.response.status == 401) {
					this.alertMessage = 'Roles.RoleManagementError';
				} else if (msg == 'ER_DATA_LENGHT') {
					this.alertMessage = 'Roles.RoleNameTooLong';
					this.alertParams = { param: newRole.name };
					this.dismissSecs = 30;
				} else {
					this.alertMessage = 'Roles.GenericError';
				}
				this.alertVariant = 'danger';
			}).finally(() => {
				this.inProgress = false;
				this.$refs.cgLocAlert.showAlert();
			});
		},
		deleteRole() {
			this.inProgress = true;

			roleService.deleteRole(this.roleToDelete.role_id).then(() => {
				console.debug("Roles - deleteRole success");
					
				this.alertVariant = 'success';
				this.alertMessage = 'Roles.RoleDeleted';

				// Refresh table to reload data
				this.$nextTick(() => {
					this.$refs.roleListTable.refresh();
				});	
			}).catch((error) => {
				console.error("Roles - deleteRole error", error);
				this.alertVariant = 'danger';
				this.alertMessage = 'Roles.RoleDeleteError';
			}).finally(() => {
				this.inProgress = false;
				this.roleToDelete = {};
				this.$refs.cgLocAlert.showAlert();
			});
		},
		getCurrentRolePage(ctx) {
			const promise = roleService.getRoles(ctx.filter, ctx.currentPage, ctx.perPage, ctx.sortBy, ctx.sortDesc);
			
			return promise.then(result => {
				const items = result.data.roles;
				this.totalRows = result.data.count;
				return items || [];
			});
		}
	},
	async created() {
		let result = await syspropService.getAllPermissions();
		let permissions = result.data;
		
		let dataset = {};

		let sections = [{ key: "account", label: this.$t('Roles.Account') },
						{ key: "campaign", label: this.$t('Roles.Campaign') },
						{ key: "company", label: this.$t('Roles.Company') },
						{ key: "target", label: this.$t('Roles.Target') },
						{ key: "template", label: this.$t('Roles.Template') },
						{ key: "landing-page", label: this.$t('Roles.LandingPage') },
						{ key: "role", label: this.$t('Roles.Role') },
						{ key: "other", label: this.$t('Roles.Other') }];

		sections.forEach((sec) => {
			dataset[sec.key] = permissions.filter((i) => {return i.name.indexOf(sec.key) !== -1});
			permissions = permissions.filter((i) => { return i.name.indexOf(sec.key) === -1; });
		});

		dataset.other = permissions;
		
		Vue.set(this.data.fields.find(i => i.id === 'permissions'), 'dataset', dataset);
		Vue.set(this.data.fields.find(i => i.id === 'permissions'), 'sections', sections);

        this.fields = [{ key: 'name', sortable: true }];

		if(this.$checkPermission('modify-role') || this.$checkPermission('delete-role')) {
			this.fields.push({ key: "actions", label: this.$t('General.Actions'), class: 'action-column' });
        }
	}
}
</script>