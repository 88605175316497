import axios from '../plugins/axios.js'

class RoleService {

	createRole(data) {
		return axios.post("/roles", data);
	}

	updateRole(data) {
		return axios.put("/roles", data);
	}

	getRoles(searchText, page, numRes, sortBy, sortDesc) {
		let opt = { params: { searchText: searchText, page: page, numRes: numRes, sortBy: sortBy, sortDesc: sortDesc } };
		return axios.get("/roles", opt);
	}

	deleteRole(roleId) {
		return axios.delete(`/roles/${roleId}`);
	}

	getRolePermissions(roleId, data) {
		return axios.get(`roles/${roleId}/permissions`, data);
	}

}

export default new RoleService();